import {AssetDetailSummaryChange} from "./AssetDetailSummaryChange";


export class AssetDetailSummaryChanges {

    public daily: AssetDetailSummaryChange | undefined;

    public monthly: AssetDetailSummaryChange | undefined;

    public weekly: AssetDetailSummaryChange | undefined;


    constructor(daily: AssetDetailSummaryChange | undefined, monthly: AssetDetailSummaryChange | undefined, weekly: AssetDetailSummaryChange | undefined) {

        this.daily = daily;
        this.monthly = monthly;
        this.weekly = weekly;

    }

}
