import {AssetDetailSummaryChanges} from "./AssetDetailSummaryChanges";


export class AssetDetailSummary {

    public changes: AssetDetailSummaryChanges | undefined;


    constructor(changes: AssetDetailSummaryChanges | undefined) {

        this.changes = changes;

    }

}
