import {DataAssetCandlestickChange} from "../data/asset/DataAssetCandlestickChange";


export class AssetDetailSummaryChange {

    public highest: DataAssetCandlestickChange | undefined;

    public lowest: DataAssetCandlestickChange | undefined;


    constructor(highest: DataAssetCandlestickChange | undefined, lowest: DataAssetCandlestickChange | undefined) {

        this.highest = highest;
        this.lowest = lowest;

    }

}
