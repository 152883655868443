import React, {Fragment, MutableRefObject, useEffect, useRef, useState} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import {Link, NavigateFunction, Outlet, useLocation, useNavigate} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import {Platform} from "../../enums/core/Platform";
import {UserLogType} from "../../enums/user/log/UserLogType";
import {UserType} from "../../enums/user/UserType";
import {Core} from "../../libraries/Core";
import {Rest} from "../../libraries/Rest";
import {InitializeAccountRequest} from "../../models/user/log/InitializeAccountRequest";
import {LogoutRequest} from "../../models/user/log/LogoutRequest";
import {User} from "../../models/user/User";
import {Vpn} from "../../models/vpn/Vpn";


// @ts-ignore
export default function LayoutComponent({preloader}) {

    const location: any = useLocation();
    const navigate: NavigateFunction = useNavigate();

    const perfectScrollbar: MutableRefObject<PerfectScrollbar | null> = useRef<PerfectScrollbar | null>(null);

    const [account, changeAccount] = useState<User>(Core.initializeUser());
    const [vpn, changeVpn] = useState<Vpn>(Core.initializeVpn());

    const accountClose = () => {

        let popup: HTMLCollectionOf<HTMLElement> = (document.getElementsByClassName('header-account-popup') as HTMLCollectionOf<HTMLElement>);

        if(popup.length > 0) {

            let opacity: number = 1;
            let decrement: number = 1 / 20;
            let fade = setInterval(() => {

                if(opacity === 0) {

                    clearInterval(fade);
                    popup[0].style.display = 'none';

                } else {

                    opacity -= decrement;

                    if(opacity < 0) {

                        opacity = 0;

                    }

                    popup[0].style.opacity = opacity.toString();

                }

            }, 1);

        }

    }

    const accountToggle = () => {

        let popup: HTMLCollectionOf<HTMLElement> = (document.getElementsByClassName('header-account-popup') as HTMLCollectionOf<HTMLElement>);

        if(popup.length > 0) {

            if(window.getComputedStyle(popup[0]).getPropertyValue('display') === 'block') {

                accountClose();

            } else {

                popup[0].style.display = 'block';

                let opacity: number = 0;
                let increment: number = 1 / 20;
                let fade = setInterval(() => {

                    if(opacity === 1) {

                        clearInterval(fade);

                    } else {

                        opacity += increment;

                        if(opacity > 1) {

                            opacity = 1;

                        }

                        popup[0].style.opacity = opacity.toString();

                    }

                }, 1);

            }

        }

    }

    const canvasClose = () => {

        let canvas: HTMLCollectionOf<HTMLElement> = (document.getElementsByClassName('canvas-wrapper') as HTMLCollectionOf<HTMLElement>);

        if(canvas.length > 0) {

            canvas[0].classList.remove('expanded');

            setTimeout(function() {

                canvasWrapperClose();

            }, 200);

        }

    }

    const canvasOpen = () => {

        let canvas: HTMLCollectionOf<HTMLElement> = (document.getElementsByClassName('canvas-wrapper') as HTMLCollectionOf<HTMLElement>);

        if(canvas.length > 0) {

            canvas[0].style.display = 'block';

            let opacity: number = 0;
            let increment: number = 1 / 20;
            let fade = setInterval(() => {

                if(opacity === 1) {

                    clearInterval(fade);

                    let title: HTMLCollectionOf<HTMLElement> = (document.getElementsByClassName('canvas-data-title') as HTMLCollectionOf<HTMLElement>);
                    let form: HTMLCollectionOf<HTMLElement> = (document.getElementsByClassName('canvas-data-form') as HTMLCollectionOf<HTMLElement>);
                    let action: HTMLCollectionOf<HTMLElement> = (document.getElementsByClassName('canvas-data-action') as HTMLCollectionOf<HTMLElement>);

                    if(title && form && action) {

                        let height: number = window.innerHeight - (parseFloat(window.getComputedStyle(title[0]).getPropertyValue('height')) + parseFloat(window.getComputedStyle(action[0]).getPropertyValue('height')));
                        form[0].style.height = height.toString() + 'px';

                    }

                } else {

                    opacity += increment;

                    if(opacity > 1) {

                        opacity = 1;

                    }

                    canvas[0].style.opacity = opacity.toString();

                }

            }, 1);

            setTimeout(function() {

                canvas[0].classList.add('expanded');

            }, 300);

        }

    }

    const canvasWrapperClose = () => {

        let canvas: HTMLCollectionOf<HTMLElement> = (document.getElementsByClassName('canvas-wrapper') as HTMLCollectionOf<HTMLElement>);

        if(canvas.length > 0) {

            let opacity: number = 1;
            let decrement: number = 1 / 20;
            let fade = setInterval(() => {

                if(opacity === 0) {

                    clearInterval(fade);
                    canvas[0].style.display = 'none';

                } else {

                    opacity -= decrement;

                    if(opacity < 0) {

                        opacity = 0;

                    }

                    canvas[0].style.opacity = opacity.toString();

                }

            }, 1);

        }

    }

    const initializeAccount = (authentication: string | undefined = undefined) => {

        fetch(process.env.REACT_APP_API_URL + '/user/log/initialize-account/', Rest.initializeRequest(
            new InitializeAccountRequest(undefined, undefined, authentication),
            '/user/log/initialize-account/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                if(data.log.type === UserLogType.Login) {

                    localStorage.setItem('version', data.user.layout.version.toString());
                    localStorage.setItem('aside', data.user.layout.sidebar?.toString());

                    changeAccount(data.user);
                    changeVpn(data.vpn);

                    if(data.log.type === UserLogType.Login && !data.log.remember) {

                        const dayDifference: number = (new Date().getTime() - new Date(data.log.created.timestamp).getTime()) / (1000 * 60 * 60 * 24);

                        if(dayDifference > 1) {

                            localStorage.clear();

                            navigate('/authentication/');

                        }

                    }

                    layout();

                } else {

                    localStorage.clear();

                    navigate('/authentication/');

                }

            } else {

                localStorage.clear();

                navigate('/authentication/');

            }

        });

    }

    const layout = () => {

        let aside: HTMLCollectionOf<HTMLElement> = (document.getElementsByTagName('aside') as HTMLCollectionOf<HTMLElement>);

        if(aside.length > 0) {

            let menu: HTMLCollectionOf<HTMLElement> = (aside[0].getElementsByClassName('aside-menu') as HTMLCollectionOf<HTMLElement>);
            let logo: HTMLCollectionOf<HTMLElement> = (aside[0].getElementsByClassName('aside-logo') as HTMLCollectionOf<HTMLElement>);

            if(menu.length > 0 && logo.length > 0) {

                let height: number = parseFloat(window.getComputedStyle(aside[0]).getPropertyValue('height')) - (parseFloat(window.getComputedStyle(logo[0]).getPropertyValue('height')) + parseFloat(window.getComputedStyle(logo[0]).getPropertyValue('padding-top')));
                menu[0].style.height = height.toString() + 'px';

            }

        }

        let asideMenu: HTMLCollectionOf<HTMLElement> = (aside[0].getElementsByClassName('aside-menu-item') as HTMLCollectionOf<HTMLElement>);

        if(asideMenu.length > 0) {

            Array.from(asideMenu).forEach(function(element: any) {

                let anchor: HTMLCollectionOf<HTMLElement> = (element.getElementsByTagName('a') as HTMLCollectionOf<HTMLElement>);

                if(anchor.length > 0) {

                    let url: string = window.location.pathname.replaceAll('/', '');
                    // @ts-ignore
                    let anchorUrl: string = anchor[0].getAttribute('href').replaceAll('/', '');

                    let whitelists: string[] = ['assets'];
                    let whitelisted: boolean = false;

                    whitelists.forEach(function(whitelist: string) {

                        if(url.includes(whitelist) && anchorUrl.includes(whitelist)) {

                            whitelisted = true;

                        }

                    });

                    if(whitelisted || url === anchorUrl) {

                        element.classList.add('active');

                    } else {

                        element.classList.remove('active');

                    }

                }

            });

        }

        preloader();

    }

    const logout = (event: any) => {

        fetch(process.env.REACT_APP_API_URL + '/user/log/logout/', Rest.initializeRequest(
            new LogoutRequest(undefined, undefined, Core.initializeAuthentication(), Platform.Desktop),
            '/user/log/logout/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                localStorage.removeItem('authentication');

                navigate('/authentication/');

            } else {

                toast.error(data.response);

            }

        }).catch(error => {

            toast.error(error);

        });

        event.preventDefault();

    }

    const sidebarMaximize = () => {

        let canvas: HTMLCollectionOf<HTMLElement> = (document.getElementsByClassName('canvas-wrapper') as HTMLCollectionOf<HTMLElement>);
        let aside: HTMLCollectionOf<HTMLElement> = (document.getElementsByTagName('aside') as HTMLCollectionOf<HTMLElement>);

        if(canvas.length > 0 && aside.length > 0) {

            canvas[0].style.display = 'block';

            let opacity: number = 0;
            let increment: number = 1 / 20;
            let fade = setInterval(() => {

                if(opacity === 1) {

                    clearInterval(fade);

                } else {

                    opacity += increment;

                    if(opacity > 1) {

                        opacity = 1;

                    }

                    canvas[0].style.opacity = opacity.toString();

                }

            }, 1);

            setTimeout(() => {

                if(window.getComputedStyle(aside[0]).getPropertyValue('transform').includes('-215')) {

                    aside[0].style.transform = 'translateX(0)';

                }

            }, 200);

        }

    }

    const sidebarMinimize = () => {

        let aside: HTMLCollectionOf<HTMLElement> = (document.getElementsByTagName('aside') as HTMLCollectionOf<HTMLElement>);

        if(aside.length > 0) {

            if(!window.getComputedStyle(aside[0]).getPropertyValue('transform').includes('-215')) {

                aside[0].style.transform = 'translateX(-215px)';

            }

            canvasWrapperClose()

        }

    }

    const sidebarToggle = () => {

        let wrapper: HTMLElement = (document.getElementById('wrapper') as HTMLElement);

        if(wrapper) {

            if(wrapper.classList.contains('collapsed')) {

                wrapper.classList.remove('collapsed');

                let toggle: HTMLCollectionOf<HTMLElement> = (document.getElementsByClassName('aside-toggle') as HTMLCollectionOf<HTMLElement>);

                if(toggle.length > 0) {

                    let icon: HTMLCollectionOf<HTMLElement> = (toggle[0].getElementsByTagName('i') as HTMLCollectionOf<HTMLElement>);

                    if(icon.length > 0) {

                        icon[0].classList.remove('ti-chevrons-right');
                        icon[0].classList.add('ti-chevrons-left');

                    }

                }

            } else {

                wrapper.classList.add('collapsed');

                let toggle: HTMLCollectionOf<HTMLElement> = (document.getElementsByClassName('aside-toggle') as HTMLCollectionOf<HTMLElement>);

                if(toggle.length > 0) {

                    let icon: HTMLCollectionOf<HTMLElement> = (toggle[0].getElementsByTagName('i') as HTMLCollectionOf<HTMLElement>);

                    if(icon.length > 0) {

                        icon[0].classList.remove('ti-chevrons-left');
                        icon[0].classList.add('ti-chevrons-right');

                    }

                }

            }

        }

        setTimeout(() => {

            layout();

        }, 500);

    }

    useEffect((): any => {

        layout();

        let authentication: string | undefined = Core.initializeAuthentication();

        if(authentication === undefined) {

            navigate('/authentication/');

        } else {

            initializeAccount(authentication);

        }

        document.addEventListener('click', function(event) {

            let accountWrapper: HTMLCollectionOf<HTMLElement> = (document.getElementsByClassName('header-account-wrapper') as HTMLCollectionOf<HTMLElement>);
            let accountPopup: HTMLCollectionOf<HTMLElement> = (document.getElementsByClassName('header-account-popup') as HTMLCollectionOf<HTMLElement>);

            if(accountWrapper.length > 0 && accountPopup.length > 0) {

                if(!accountWrapper[0].contains(event.target as Node) && !accountPopup[0].contains(event.target as Node)) {

                    accountClose();

                }

            }

        });

        let wrapper: HTMLElement = (document.getElementById('wrapper') as HTMLElement);
        let header: HTMLCollectionOf<HTMLElement> = (document.getElementsByTagName('header') as HTMLCollectionOf<HTMLElement>);
        let content: HTMLElement = (document.getElementById('content') as HTMLElement);
        let footer: HTMLCollectionOf<HTMLElement> = (document.getElementsByTagName('footer') as HTMLCollectionOf<HTMLElement>);

        if(wrapper && header.length > 0 && content && footer.length > 0) {

            wrapper.onscroll = function() {

                let scrollY: number = wrapper.scrollTop;

                if(scrollY >= 5) {

                    header[0].style.top = '0';

                } else {

                    header[0].style.top = '15px';

                }

                if(scrollY >= ((wrapper.scrollHeight - parseFloat(window.getComputedStyle(wrapper).getPropertyValue('height'))) - 5)) {

                    footer[0].style.bottom = '15px';

                } else {

                    footer[0].style.bottom = '0';

                }

            }

        }

        window.onresize = function() {

            layout();

            if(perfectScrollbar.current) {

                perfectScrollbar.current.updateScroll();

            }

        };

    }, [location]);

    return (
        <div id="wrapper">
            <ToastContainer position="top-right" autoClose={5000} closeOnClick newestOnTop={true} pauseOnHover={false} theme="dark"></ToastContainer>
            <div id="preloader">
                <div className="cube">
                    <div className="load-cube c1"></div>
                    <div className="load-cube c2"></div>
                    <div className="load-cube c4"></div>
                    <div className="load-cube c3"></div>
                </div>
            </div>
            <aside>
                <div className="aside-wrapper">
                    <div className="aside-logo">
                        <h1>
                            <Link to={'/'}>
                                <img src="/images/logo/logo.png" alt={process.env.REACT_APP_COMPANY_NAME}/>
                            </Link>
                        </h1>
                        <h2>
                            <Link to={'/'}>{process.env.REACT_APP_APPLICATION_NAME}</Link>
                        </h2>
                        <div className="aside-toggle" onClick={() => sidebarToggle()}>
                            <i className="ti ti-chevrons-left"></i>
                        </div>
                        <div className="aside-toggle-mobile" onClick={() => sidebarMinimize()}>
                            <i className="ti ti-chevrons-left"></i>
                        </div>
                    </div>
                    <div className="aside-menu">
                        <PerfectScrollbar ref={perfectScrollbar} options={{'wheelSpeed': 0.5}}>
                            <ul>
                                <li>
                                    <div className="aside-menu-item">
                                        <Link to={'/'}>
                                            <i className="ti ti-layout-dashboard mr-5"></i>
                                            <span>Dashboard</span>
                                        </Link>
                                        <div className="aside-menu-toggle">
                                            <i className="ti ti-chevron-right"></i>
                                        </div>
                                    </div>
                                </li>
                                {
                                    (account.type === UserType.Owner || account.type === UserType.Administrator) &&
                                    <Fragment>
                                        <li className="aside-menu-title">
                                            <p><i className="ti ti-coin mr-5"></i>Markets</p>
                                        </li>
                                        <li>
                                            <div className="aside-menu-item">
                                                <Link to={'/assets/'}>
                                                    <i className="ti ti-coin mr-5"></i>
                                                    <span>Assets</span>
                                                </Link>
                                            </div>
                                        </li>
                                    </Fragment>
                                }
                                <li className="aside-menu-title">
                                    <p><i className="ti ti-user-check mr-5"></i>Account</p>
                                </li>
                                <li>
                                    <div className="aside-menu-item">
                                        <Link to={'/account/'}>
                                            <i className="ti ti-user-scan mr-5"></i>
                                            <span>Profile</span>
                                        </Link>
                                    </div>
                                </li>
                                <li className="aside-menu-title">
                                    <p><i className="ti ti-lock-access-off mr-5"></i>Nawala</p>
                                </li>
                                {
                                    (account.type === UserType.Owner || account.type === UserType.Administrator) &&
                                    <li>
                                        <div className="aside-menu-item">
                                            <Link to={'/nawala/groups/'}>
                                                <i className="ti ti-buildings mr-5"></i>
                                                <span>Group</span>
                                            </Link>
                                        </div>
                                    </li>
                                }
                                <li>
                                    <div className="aside-menu-item">
                                        <Link to={'/nawala/urls/'}>
                                            <i className="ti ti-world-www mr-5"></i>
                                            <span>URL</span>
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <div className="aside-menu-item">
                                        <Link to={'/nawala/reports/'}>
                                            <i className="ti ti-report mr-5"></i>
                                            <span>Report</span>
                                        </Link>
                                    </div>
                                </li>
                                {
                                    (account.type === UserType.Owner || account.type === UserType.Administrator) &&
                                    <li className="aside-menu-title">
                                        <p><i className="ti ti-users mr-5"></i>Accounts</p>
                                    </li>
                                }
                                {
                                    (account.type === UserType.Owner || account.type === UserType.Administrator) &&
                                    <li>
                                        <div className="aside-menu-item">
                                            <Link to={'/users/'}>
                                                <i className="ti ti-user-shield mr-5"></i>
                                                <span>Users</span>
                                            </Link>
                                        </div>
                                    </li>
                                }
                                <li className="aside-menu-title">
                                    <p><i className="ti ti-brand-openvpn mr-5"></i>VPN</p>
                                </li>
                                {
                                    (account.type === UserType.Owner || account.type === UserType.Administrator) &&
                                    <Fragment>
                                        <li>
                                            <div className="aside-menu-item">
                                                <Link to={'/vpn/'}>
                                                    <i className="ti ti-brand-openvpn mr-5"></i>
                                                    <span>VPN</span>
                                                </Link>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="aside-menu-item">
                                                <Link to={'/vpn/servers/'}>
                                                    <i className="ti ti-server mr-5"></i>
                                                    <span>Server</span>
                                                </Link>
                                            </div>
                                        </li>
                                    </Fragment>
                                }
                                <li>
                                    <div className="aside-menu-item">
                                        <Link to={'/vpn/accounts/'}>
                                            <i className="ti ti-user-circle mr-5"></i>
                                            <span>Account</span>
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </PerfectScrollbar>
                    </div>
                </div>
            </aside>
            <header>
                <div className="header-wrapper">
                    <div className="header-menu">
                        <i className="ti ti-menu-2" onClick={() => sidebarMaximize()}></i>
                    </div>
                    <div className="header-search">
                        <input name="search" placeholder="Search..."/>
                        <div className="header-search-icon">
                            <i className="ti ti-search"></i>
                        </div>
                    </div>
                    <div className="header-account-wrapper">
                        <div className="header-account">
                            <div className="header-account-name" onClick={() => accountToggle()}>
                                <p>{Core.initializeUserName(account.name!)}</p>
                                <p>{Core.toStandardCase(account.type!)}</p>
                            </div>
                            <div className="header-account-avatar" onClick={() => accountToggle()}>
                                <img src="/images/avatar/avatar-male.png" alt={process.env.REACT_APP_COMPANY_NAME + ' Avatar'}/>
                                <div className="header-account-avatar-status"></div>
                            </div>
                        </div>
                        <div className="header-account-popup">
                            <div className="header-account-popup-account">
                                <div className="header-account-popup-avatar">
                                    <img src="/images/avatar/avatar-male.png" alt={process.env.REACT_APP_COMPANY_NAME + ' Avatar'}/>
                                    <div className="header-account-avatar-status"></div>
                                </div>
                                <div className="header-account-popup-name">
                                    <p>{Core.initializeUserName(account.name!)}</p>
                                    <p>{Core.toStandardCase(account.type!)}</p>
                                </div>
                            </div>
                            <div className="header-account-popup-row">
                                <button className="logout" onClick={(event) => logout(event)}>
                                    <i className="ti ti-logout mr-5"></i>Logout
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div id="content">
                <Outlet context={{account, canvasClose, canvasOpen, vpn}}></Outlet>
            </div>
            <footer>
                <div className="footer-wrapper">
                    Copyright © {new Date().getFullYear()}&nbsp;
                    <Link to={'/'}>{process.env.REACT_APP_COMPANY_NAME}</Link>.&nbsp;
                    All rights reserved.
                </div>
            </footer>
        </div>
    );

}
